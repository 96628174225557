@use '@/scss/underscore' as _;

.inputField {
  position: relative;
  @include _.flex-row;
  border: _.border(var(--color-line-border));
  border-radius: var(--radius);
  overflow: hidden;
  transition-property: outline, border;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  align-items: stretch;

  // fix in safari input field line-height issue
  height: 44px;

  input {
    transition: width 0.3s ease-in;
    padding: 0 _.unit(4);
    flex: 1;
    background: var(--color-bg-body);
    caret-color: var(--color-brand-default);
    font: var(--font-body-1);
    color: var(--color-type-primary);

    &::placeholder {
      color: var(--color-type-secondary);
    }
  }

  .suffix {
    position: absolute;
    right: _.unit(2);
    top: 50%;
    transform: translateY(-50%);
    width: _.unit(8);
    height: _.unit(8);
    display: none;
    z-index: 1;
  }


  &:focus-within {
    border: _.border(var(--color-brand-default));

    input {
      outline: none;
    }
  }

  &.danger {
    border: _.border(var(--color-danger-default));

    input {
      caret-color: var(--color-danger-default);
    }
  }

  &.isSuffixVisible,
  &.isSuffixFocusVisible:focus-within {
    input {
      padding-right: _.unit(10);
    }

    .suffix {
      display: flex;
    }
  }
}

.errorMessage {
  margin-top: _.unit(1);
  margin-left: _.unit(0.5);
}

:global(body.desktop) {
  .inputField {
    outline: 3px solid transparent;

    /* stylelint-disable-next-line no-descending-specificity */
    input {
      font: var(--font-body-2);
      background: var(--color-bg-float);
    }

    &:focus-within {
      outline-color: var(--color-overlay-brand-focused);
    }

    &.danger:focus-within {
      outline-color: var(--color-overlay-danger-focused);
    }
  }
}
