@use '@/scss/underscore' as _;


.divider {
  @include _.flex-row;
  font: var(--font-body-2);
  color: var(--color-type-secondary);

  .line {
    flex: 1;
    height: 1px;
    background: var(--color-line-divider);

    &:first-child {
      margin-right: _.unit(4);
    }

    &:last-child {
      margin-left: _.unit(4);
    }
  }
}
