@use '@/scss/underscore' as _;

.button {
  @include _.flex-row;
  justify-content: center;
  height: 44px;
  padding: 0 _.unit(4);
  border-radius: var(--radius);
  cursor: pointer;
  font: var(--font-label-1);
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  transition: background 0.2s ease-in-out;
  user-select: none;
  overflow: hidden;
}

.large {
  width: 100%;
}

.small {
  min-width: 85px;
}

.primary {
  border: none;
  background: var(--color-brand-default);
  color: var(--color-static-white);

  &.disabled,
  &:disabled {
    background: var(--color-bg-state-disabled);
    color: var(--color-type-disable);
  }

  &:active {
    background: var(--color-brand-pressed);
  }
}

.secondary {
  border: _.border(var(--color-line-border));
  background: transparent;
  color: var(--color-type-primary);

  &.disabled,
  &:disabled {
    border-color: var(--color-type-disable);
    color: var(--color-type-disable);
  }

  &:active {
    background: var(--color-overlay-neutral-pressed);
  }
}


:global(body.desktop) {
  .button {
    font: var(--font-label-2);
  }

  .primary {
    &:focus-visible {
      outline: 3px solid var(--color-overlay-brand-focused);
    }

    &:not(:disabled):not(:active):hover {
      background: var(--color-brand-hover);
    }
  }

  .secondary {
    &:focus-visible {
      outline: 3px solid var(--color-overlay-neutral-focused);
    }

    &:not(:disabled):not(:active):hover {
      background: var(--color-overlay-neutral-hover);
    }
  }
}
