@use '@/scss/underscore' as _;

.socialLinkList {
  @include _.flex-column;
}

.socialLinkButton {
  margin-bottom: _.unit(4);

  &:last-child {
    margin-bottom: 0;
  }
}

.expandIcon {
  > svg {
    width: 20px;
    height: 20px;
  }

  &.expanded {
    transform: rotate(180deg);
  }
}
